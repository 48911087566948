import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import './MyFocuses.css';
import { CONTACT_INFO } from '../../constants/routes';

const arrowRight = <FontAwesomeIcon icon={faArrowRight} size='1x' />;

const MyFocuses = () => (
	<div>
		<span className='page-number'>03</span>
		<div className='strike-through'></div>

		<div className='row'>
			<div className='col-md-6'>
				<div className='section'>
					<h1 className='page-title'>My Focuses.</h1>

					<p className='page-text'>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras 
						vestibulum, nisi in viverra pharetra, enim nibh egestas dolor, et 
						sodales lacus erat et magna. Quisque tincidunt nisl consectetur 
						consequat fermentum. Integer gravida ante vel viverra hendrerit. Nam 
						tincidunt tincidunt laoreet. Cras cursus massa a massa sodales pulvinar. 
						Maecenas a blandit leo, ac tincidunt sem. Morbi ac ligula urna. Cras 
						pellentesque tristique est, eget pretium neque. Etiam elementum 
						condimentum ornare. Cras a facilisis metus, id euismod urna. Mauris eget 
						felis pellentesque neque interdum aliquet. Sed ullamcorper lectus in 
						odio facilisis, vitae dignissim turpis rutrum.
					</p>

					<p className='page-text'>
						Sed volutpat nisi sed diam sagittis, ut lobortis erat scelerisque. 
						Aenean consequat vehicula metus vel tempus. Donec eget scelerisque 
						libero. Donec suscipit dictum odio a molestie. Curabitur lacinia 
						condimentum ultrices. Maecenas malesuada, lectus sed tristique tempus, 
						ligula diam scelerisque sem, sed scelerisque metus sapien fermentum 
						erat. Donec euismod venenatis eros a dignissim. Etiam eleifend interdum 
						lit. Sed ut diam aliquet, interdum purus id, scelerisque purus. Donec 
						vitae purus ante. Cras feugiat pharetra lectus. Nullam tempus blandit 
						nulla, id commodo nibh pulvinar et.
					</p>
				</div>

				<Link to={CONTACT_INFO} className='link'>
					CONTACT INFO
					<br />
					<span class='arrow'>{arrowRight}</span>
				</Link>
			</div>

			<div className='image-grid d-none d-sm-block col-md-6'>
				<img className='about-image-a image' src='https://images.unsplash.com/photo-1416339684178-3a239570f315?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=967&q=80' />             
			  <img className='about-image-b image' src='https://images.unsplash.com/photo-1540397347091-1f1df8f56f4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1534&q=80' />             
			  <img className='about-image-c image' src='https://images.unsplash.com/photo-1524758870432-af57e54afa26?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80' />
		  </div>
		</div>
	</div>
);

export default MyFocuses;