import React from 'react';

import ProfileImage from '../../assets/profile.png'
import Navigation from './Navigation/Navigation';
import './Home.css'

const Home = () => (
	<div>
		<div className='row'>
			<div className='col-md-6'>
				<span className='hello'>
					HE
					<br className='d-none d-sm-block' />
					LLO.
				</span>

				<div className='underline'></div>
			</div>

			<div className='col-md-6'>
				<div className='profile-pic-container'>
					<img className='profile-pic' 
							 src={ProfileImage} 
							 alt='Nick Lauwers' />
				</div>
			</div>
		</div>
				
		<div className='sidebar'></div>

		<div className='intro'>
			<span className='intro-secondary'>I'm </span>
			<span className='intro-primary'>Nick Lauwers</span>
			<span className='intro-secondary'>, an </span>
			<span className='intro-primary'>engineer</span>
			<span className='intro-secondary'> and </span>
			<br />
			<span className='intro-primary'>design enthusiast</span>
			<span className='intro-secondary'> based in New York City.</span>
		</div>

		<Navigation />
	</div>
);

export default Home;