import React from 'react';
import { Link } from 'react-router-dom';

import './NavigationItem.css';

const NavigationItem = (props) => (
	<li className='col-md-3'>
		<Link to={props.link} className='navigation-link'>
			<span className='navigation-number'>0{props.list_id}</span>
			<span className='navigation-title'>{props.title.toUpperCase()}</span>
		</Link>
	</li>
);

export default NavigationItem;