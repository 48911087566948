import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { MY_WORK } from '../../constants/routes';
import './AboutMe.css';

const arrowRight = <FontAwesomeIcon icon={faArrowRight} size='1x' />;

const AboutMe = () => (
	<div>
		<span className='page-number'>01</span>
		<div className='strike-through'></div>

		<div className='section'>	
			<h1 className='page-title'>About Me.</h1>

			<p className='page-text'>
				Experienced Ruby-on-Rails developer (2+ years) with repeated success 
				building full-stack product experiences. Founder and lead developer of 
				online marketplace for powersport & recreational vehicles. Leveraged 
				expertise in business development, digital transformation, and project 
				management to launch and scale applications actively servicing 
				customers. Proven success (4 years) developing vehicle hardware & 
				software for the nation’s second-largest automotive manufacturer. 
				Former military officer with robust leadership experience and a 
				well-rounded management approach.
			</p>

			
			<p className='page-text'>
				Skills: <br />
				• Languages | Ruby on Rails; HTML5 / CSS3; Javascript. <br />
				• Data Management | PostgreSQL; MySQL; ElasticSearch. <br />
				• Cloud Platforms | Heroku; AWS Cloud9; Amazon S3. <br />
				• Integrations | SendGrid; Mapbox; Hotjar; Facebook Pixel. 
			</p>
		</div>

		<div className='row'>
			<div className='col'>
				<a href='https://app.box.com/s/uj37yit64na93quvedpodbdn60cvf7vt'
		  		 className='link' 
		  	   target='_blank'
		       rel='noopener noreferrer'>
		    	MY RESUME 
		    	<br />
		    	<span className='arrow'>{arrowRight}</span>
		    </a>
		  </div>

		  <div className='col'>
		    <Link to={MY_WORK} className='link'>
					MY WORK 
					<br />
					<span className='arrow'>{arrowRight}</span>
				</Link>
		  </div>
	  </div>
	</div>
);

export default AboutMe;