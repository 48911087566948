import React from 'react';

import './Footer.css';

const Footer = () => (
	<div className='footer'>
		<div className='footer-content'>
			<span role='img' aria-label='hello'>&#128075;</span>
			<span> : nlauwers90@gmail.com</span>

			<span className='float-right d-none d-sm-inline-block'>
				&#169; {(new Date().getFullYear())} | Nick Lauwers
			</span>
		</div>
	</div>
);

export default Footer;