import React from 'react';

import NavigationItem from './NavigationItem';
import { pages } from '../../../utils/pages';
import './Navigation.css';

const navigation_items = pages.slice(1).map((page) => {
	return <NavigationItem key={page.id} 
												 list_id={page.id}
												 title={page.title}
												 link={page.link} />
});

const Navigation = () => (
	<div className='row'>
		<ol className='navigation-items'>
			{navigation_items}
		</ol>
	</div>
);

export default Navigation;