import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { HOME } from '../../constants/routes';

const arrowRight = <FontAwesomeIcon icon={faArrowRight} size='1x' />;

const ContactInfo = () => (
	<div>
		<span className='page-number'>04</span>
		<div className='strike-through'></div>

		<div className='section'>
			<h1 className='page-title'>Contact Info.</h1>

			<p className='page-text'>
				I enjoy creating things with fun, open-minded people. Feel free to say 
				hello! Please email nlauwers90@gmail.com.
			</p>
		</div>

		<Link to={HOME} className='link'>
			HOMEPAGE
			<br />
			<span className='arrow'>{arrowRight}</span>
		</Link>
	</div>
);

export default ContactInfo;