import * as ROUTES from '../constants/routes';

export const pages = [ { id: 0, title: 'Home', link: ROUTES.HOME },
											 { id: 1, title: 'About Me', link: ROUTES.ABOUT_ME },
											 { id: 2, title: 'My Work', link: ROUTES.MY_WORK },
											 { id: 3, title: 'My Focuses', link: ROUTES.MY_FOCUSES },
											 { 
												 id: 4, 
											   title: 'Contact Info', 
											   link: ROUTES.CONTACT_INFO 
											 } ];