import React, {useState} from 'react';

import Header from './Header/Header';
import Menu from './Menu/Menu';

const Navigation = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	function handleMenu() {
		setIsMenuOpen(!isMenuOpen)
	}

	return (
		<>
			<Header onToggle={handleMenu} />
			<Menu isMenuOpen={isMenuOpen} onToggle={handleMenu} />
		</>
	);
};

export default Navigation;