import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import MotominionImage from '../../assets/motominion.png';
import { MY_FOCUSES } from '../../constants/routes';
import './MyWork.css';

const arrowRight = <FontAwesomeIcon icon={faArrowRight} size='1x' />;

const MyWork = () => (
	<div>
		<span className='page-number'>02</span>
		<div className='strike-through'></div>

		<div className='section'>
			<h1 className='page-title'>My Work.</h1>

			<p className='page-text'>
				Motominion.com is a search-through-close listing platform for powersport & 
				recreational vehicles, connecting customers and dealers in a consolidated 
				retail experience; our mission is to digitize every step of the customer 
				journey, from search to sale.
			</p>
		</div>

		<div className='motominion-pic-container'>
			<img className='motominion-pic' src={MotominionImage} />
		</div>

		<ScrollAnimation animateIn='section-scroll' animateOnce='true'>
			<h2 className='page-subtitle'>BUSINESS DEVELOPMENT</h2>

			<p className='page-text'>
				• Drove the vision, strategy, and growth of the company from conception to 
				launch; quickly achieved positive unit economics, selling customer leads 
				to dealerships at 1.4 - 5.4x cost  <br />
				• Formulated go-to-market strategy, including plans for generating 
				consistent supply and demand in a two-sided marketplace; acquired 35 
				subscribing dealers, together contributing 8,000 vehicle listings  <br />
				• Collaborated with digital marketing agencies to develop and launch PPC 
				and SEO campaigns; advertisements generated 8,500 unique monthly visitors 
				and a social media following of 2,700
			</p>
		</ScrollAnimation>

		<div className='row section-scroll-container'>
			<div className='column-container col-md-5'>
				<ScrollAnimation className='column' 
												 animateIn='section-scroll' 
												 animateOnce='true'>
					<div className='column-text'>
						<h2 className='page-subtitle'>TECHNICAL ACHIEVEMENTS</h2>

						<p className='page-text'>
							• Wrote all of the website’s software, including all front- and 
							back-end code <br />
							• Designed overall application architecture, including a robust 
							database structure, and an efficient MVC and CRUD framework <br />
							• Designed and implemented UI using HTML, CSS, Sass, jQuery, and 
							Twitter Bootstrap; optimized to ensure fluidity across both mobile and 
							desktop platforms <br />
						</p>
					</div>
				</ScrollAnimation>
			</div>

			<div className='col-md-7 pitch'>
				<iframe src='https://docs.google.com/presentation/d/e/2PACX-1vRSy2KL-R3gR5X2t4CgswJo7_uqkhwak80oa4v1klRRsiI8johmIff9Zj5UBRR5Cu_8ld19LqcVwLXq/embed?start=false&loop=false&delayms=3000' 
								frameborder='0' 
								width='480' 
								height='299' 
								allowfullscreen='true' 
								mozallowfullscreen='true' 
								webkitallowfullscreen='true'
								className='d-none d-sm-inline-block'></iframe>

				<iframe src='https://docs.google.com/presentation/d/e/2PACX-1vRSy2KL-R3gR5X2t4CgswJo7_uqkhwak80oa4v1klRRsiI8johmIff9Zj5UBRR5Cu_8ld19LqcVwLXq/embed?start=false&loop=false&delayms=3000' 
								frameborder='0' 
								width='325' 
								height='200' 
								allowfullscreen='true' 
								mozallowfullscreen='true' 
								webkitallowfullscreen='true'
								className='d-inline-block d-sm-none'></iframe>
			</div>
		</div>

		<div className='row'>
			<div className='col'>
				<a href='https://www.youtube.com/watch?v=rsUClrlEd1k'
		  		 className='link' 
		  	   target='_blank'
		       rel='noopener noreferrer'>
		    	PRODUCT DEMO
		    	<br />
		    	<span className='arrow'>{arrowRight}</span>
		    </a>
		  </div>

		  <div className='col'>
		    <Link to={MY_FOCUSES} className='link'>
					MY FOCUSES
					<br />
					<span className='arrow'>{arrowRight}</span>
				</Link>
		  </div>
	  </div>
	</div>
);

export default MyWork;