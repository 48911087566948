import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import ScrollToTop from './components/ScrollToTop';
import Navigation from './components/Navigation/Navigation';
import Footer from './components/Footer/Footer';
import HomePage from './components/Home/Home';
import AboutMePage from './components/AboutMe/AboutMe';
import MyWorkPage from './components/MyWork/MyWork';
import MyFocusesPage from './components/MyFocuses/MyFocuses';
import ContactInfoPage from './components/ContactInfo/ContactInfo';
import * as ROUTES from './constants/routes';
import './App.css';

const App = () => (
	<Router>
    <ScrollToTop />

	  <div className='App'>
      <Navigation />

      <div className='page-content'>
      	<Route exact path={ROUTES.HOME} component={HomePage} />
      	<Route path={ROUTES.ABOUT_ME} component={AboutMePage} />
      	<Route path={ROUTES.MY_WORK} component={MyWorkPage} />
      	<Route path={ROUTES.MY_FOCUSES} component={MyFocusesPage} />
      	<Route path={ROUTES.CONTACT_INFO} component={ContactInfoPage} />
      </div>

      <Footer />
    </div>
  </Router>
);

export default App;