import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faBars } from '@fortawesome/pro-regular-svg-icons';

import { HOME } from '../../../constants/routes';
import './Header.css';

const github = <FontAwesomeIcon icon={faGithub} size='2x' />;
const linkedin = <FontAwesomeIcon icon={faLinkedinIn} size='2x' />;
const hamburger = <FontAwesomeIcon icon={faBars} size='2x' />;

const Header = ({onToggle}) => (
	<nav className='header'>
		<Link to={HOME} className='header-brand'>technick</Link>

		<div>
			<a href='https://github.com/Nick-Lauwers'
    		 className='header-link' 
    	   target='_blank'
         rel='noopener noreferrer'>
    	  {github}
    	</a>

    	<a href='https://www.linkedin.com/in/nicklauwers/' 
    		 className='header-link' 
    		 target='_blank'
         rel='noopener noreferrer'>
    		{linkedin}
    	</a>

    	<span className='header-hamburger' onClick={onToggle}>
    		{hamburger}
    	</span>
		</div>
	</nav>
);

export default Header;