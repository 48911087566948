import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { pages } from '../../../utils/pages';
import './Menu.css'

const Menu = ({isMenuOpen, onToggle}) => {
	const close = <FontAwesomeIcon icon={faTimes} size='2x' />

	const menuStyle = isMenuOpen ? 
											{ display: 'block' } : 
											{ display: 'none' };

	const menu_items = pages.map((page) => {
		return ( 
			<li className='menu-item'>
				<Link to={page.link} className='menu-link' onClick={onToggle}>
					{page.title.toLowerCase()}
				</Link>
			</li>
		)
	});

	return (
		<div className='menu' style={menuStyle}>
			<span className='menu-close' onClick={onToggle}>
				{close}
			</span>

			<ol className='menu-list'>{menu_items}</ol>
		</div>
	)
};

export default Menu;